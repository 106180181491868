import { Link as ReactRouterLink } from 'react-router-dom'
import { Image, Text, Box, Flex, Grid, GridItem, Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { projects } from "./Data/ProjectData";

const HomeSection2: React.FC = () => {
  const projectCount = projects.length;
  // const projectIDs = [2];
  // const mainProjects = projects.filter((project) => projectIDs.includes(project.id));
  return (
    <>
      {projectCount === 0 ?
        <></>
        :
    <Box marginBottom={20}>
      <Flex mb={10} justifyContent='center' alignItems='center'>
        <Text fontSize='4xl' as='mark'>Projects</Text>
      </Flex>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
        gap={6}
      >
        {projects.sort((a, b) => b.id - a.id).slice(0,3).map((project) => (
          <ChakraLink as={ReactRouterLink} to={project.link}>
            <GridItem borderRadius={10} colSpan={1} h='100%'>
            <Box maxW='100%' borderRadius='lg' overflow='hidden' h='100%'  boxShadow='xl'>
            <Image src={project.image} h={{ base: 'auto', md: '150px', xl: '300px' }} w='100%' objectFit='cover'/>

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    {/* <Badge borderRadius='full' px='2' colorScheme='teal'>
                      New
                    </Badge> */}
                    <Flex
                      direction='row'
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      flexWrap='wrap'
                    >
                      {project.techStack.map((skill) => 
                      (
                        <Text mr={2}>{skill}</Text>
                      ))}
                      
                    </Flex>
                  </Box>
                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  >
                    {project.title}
                  </Box>
                  <Box>
                    {project.description}
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </ChakraLink>

        ))}
      </Grid>
    </Box>
}
</>

  );
};

export default HomeSection2;