import React from 'react';
import { projects } from '../../Data/ProjectData';
import { Image, Box, Flex, Heading, Text, Link, HStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const RunnersUniverse: React.FC = () => {
  const id = 4;

  // Use `find` to get the project with the specific ID
  const project = projects.find((project) => project.id === id);

  return (
    <>
      {!project ?
        <Text>
          Error
        </Text>
        :
        <>
          <Flex marginBottom={10} direction='column' justifyContent='center' alignItems='center' w='100%'>
            <Box w={{ base: '100%', md: '70%', xl: '50%' }}>
              <Heading justifyContent='center' alignItems='center' as='mark' fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}>
                {project.title}
              </Heading>
              <Text textAlign='center' w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                {project.description}
              </Text>
              <HStack w='100%' spacing={5} justifyContent='center' alignItems='flex-start' mb={{ base: '5', xl: '10' }}>
                <Link href='https://github.com/thisisgurpal/run_results_app' isExternal color='teal.500' fontWeight='bold' fontSize='20px'>Project Code <ExternalLinkIcon mx='2px' /></Link>
              </HStack>
              <Image src={project.image} borderRadius={10} mt={10} mb={10} />
              <Text >
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Description:
                </Text>
                This was a solo project where I created a running app that allows visitors to get information on running events, view past years leaders, view runners profiles, view fitness and health blogs and once registered and logged in you can save favourite runners and also comment on running events. For this project I built the back end which included making my own API, I also designed and developed the front end. This was done using PostgreSQL, Python, TablePlus, React.
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Brief:
                </Text>
                <ul>
                  <li>Build a Full-Stack application making my own back end and front end.</li>
                  <li>Use a Python Django API using Django REST Framework to serve my data from a PostgreSQL database.</li>
                  <li>Consume our API with a separate front end built in React.</li>
                  <li>Have a visually impressive design.</li>
                </ul>
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Technologies and tools used:
                </Text>
                <ul>
                  <li>Python</li>
                  <li>JavaScript</li>
                  <li>Django</li>
                  <li>Django REST Framework</li>
                  <li>PostgreSQL</li>
                  <li>TablePlus</li>
                  <li>Axios</li>
                  <li>React</li>
                  <li>CSS</li>
                  <li>Chakra</li>
                  <li>Cloudinary</li>
                  <li>React Router DOM</li>
                  <li>JWT</li>
                  <li>Insomnia</li>
                  <li>Heroku</li>
                  <li>Visual Studio Code</li>
                </ul>

                <br /><br />
                If you're interested in the detail of the process and build (Documentation & Code) - <Link href='https://github.com/thisisgurpal/run_results_app' isExternal color='teal.500' fontWeight='bold'>Click here</Link>.
              </Text>
            </Box>
          </Flex>
        </>
      }
    </>
  );
};

export default RunnersUniverse;
