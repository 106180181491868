import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../components/Home";
import Projects from "../components/Project/Projects";
import PremierLeagueScrapeDashProject from "../components/Project/IndividualProject/PremierLeagueScrapeDashProject";
import RunnersUniverse from "../components/Project/IndividualProject/RunnersUniverse";
import TinyHabits from "../components/Project/IndividualProject/TinyHabits";
import Reciplease from "../components/Project/IndividualProject/Reciplease";
import Blog from "../components/Blog/Blog";
import PacmanJS from "../components/Project/IndividualProject/PacmanJS";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {path: "", element: <Home />},

            {path: "projects", element: <Projects />},
            {path: "projects/premier-league-scrape-and-visualisation", element: <PremierLeagueScrapeDashProject />},
            {path: "projects/runnersuniverse", element: <RunnersUniverse />},
            {path: "projects/tinyhabits", element: <TinyHabits />},
            {path: "projects/reciplease", element: <Reciplease />},
            {path: "projects/pacman-javascript", element: <PacmanJS />},

            {path: "blogs", element: <Blog />},
        ]
    }
])