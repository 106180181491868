// src/data/blogData.ts

export interface Project {
    id: number;
    title: string;
    description: string;
    techStack: Array<string>;
    tags: Array<string>;
    image: string;
    link: string;
  }
  
  export const projects: Project[] = [
    {
      id: 1,
      title: "Pacman using JavaScript",
      description: "Building a JavaScript game using JavaScript and CSS.",
      techStack: ['JavaScript', 'CSS', 'Git'],
      tags: ['Front End Development', 'Design', 'UX Design'],
      image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1729024124/portfolio-main/pacman-js-portfolio_ghejoq.jpg",
      link: "/projects/pacman-javascript",
    },
    {
      id: 2,
      title: "Reciplease",
      description: "An application using an API to get tasty recipes.",
      techStack: ['JavaScript', 'React', 'Public API', 'CSS', 'Git'],
      tags: ['Front End Development', 'Design', 'UX Design'],
      image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1729024125/portfolio-main/reciplease-portfolio_ye9ojd.jpg",
      link: "/projects/reciplease",
    },
    {
      id: 3,
      title: "TinyHabits",
      description: "A motivational application to boost habit building with a side of social accountability.",
      techStack: ['JavaScript', 'MongoDB', 'Express', 'Node.js', 'JWT', 'React'],
      tags: ['Full Stack Development', 'Application'],
      image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1729024124/portfolio-main/tinyhabits-portfolio_hh8ssl.jpg",
      link: "/projects/tinyhabits",
    },
    {
      id: 4,
      title: "RunnersUniverse",
      description: "All the information you need on the biggest races around the world.",
      techStack: ['Python', 'Django', 'PostgreSQL', 'React', 'JWT', 'Heroku'],
      tags: ['Full Stack Development', 'Application'],
      image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1729024125/portfolio-main/runnersuniverse-portfolio_xzstkd.jpg",
      link: "/projects/runnersuniverse",
    },
    {
      id: 5,
      title: "Premier League Webscraper and Dashboard",
      description: "Making a dashboard from scraping the Premier League website.",
      techStack: ['Python', 'Selenium', 'Tableau', 'SQL'],
      tags: ['Webscraping', 'Data Engineering', 'Data Analytics', 'Data Visualisation'],
      image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1723925232/portfolio-main/Premier_League_Dashboard_Tableau_h4vc3x.jpg",
      link: "/projects/premier-league-scrape-and-visualisation",
    }
  ];