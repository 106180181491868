import React from 'react';
import { projects } from '../../Data/ProjectData';
import { Image, Box, Flex, Heading, Text, Link, HStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const PacmanJS: React.FC = () => {
  const id = 1;

  // Use `find` to get the project with the specific ID
  const project = projects.find((project) => project.id === id);

  return (
    <>
      {!project ?
        <Text>
          Error
        </Text>
        :
        <>
          <Flex marginBottom={10} direction='column' justifyContent='center' alignItems='center' w='100%'>
            <Box w={{ base: '100%', md: '70%', xl: '50%' }}>
              <Heading justifyContent='center' alignItems='center' as='mark' fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}>
                {project.title}
              </Heading>
              <Text textAlign='center' w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                {project.description}
              </Text>
              <HStack w='100%' spacing={5} justifyContent='center' alignItems='flex-start' mb={{ base: '5', xl: '10' }}>
                <Link href='https://github.com/thisisgurpal/Pacman-Game' isExternal color='teal.500' fontWeight='bold' fontSize='20px'>Project Code <ExternalLinkIcon mx='2px' /></Link>
              </HStack>
              <Image src={project.image} borderRadius={10} mt={10} mb={10} />
              <Text >
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Description:
                </Text>
                In this solo project, I embarked on an exciting journey to recreate a cherished childhood favourite - Pacman. Armed with JavaScript, HTML, and CSS, I immersed myself in the delightful process of bringing this classic arcade game to life.
                <br /><br />
                Over the course of a week, I dedicated my efforts to crafting an engaging and functional Pacman game that would capture the essence of the original, while infusing it with a touch of creativity and innovation.
                <br /><br />
                My version of Pacman boasts several compelling features that pay homage to the beloved classic:
                <br /><br />
                <ul>
                <li>Two Unique Maps: I meticulously designed and incorporated two distinct maps, each offering a unique challenge and gameplay experience.
                Progression to the Next Level: Players can revel in the excitement of advancing to the next level upon successful completion of each stage.</li>
                <li>True to the Original: Emphasizing authenticity, I ensured that the game retained all the familiar characteristics of the classic Pacman, creating a nostalgic and enjoyable experience for players.</li>
                </ul>
                <br /><br />
                Try It Out!
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Brief:
                </Text>
                <ul>
                  <li>To create a game of your choice in a week using Vanilla JavaScript.</li>
                </ul>
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Technologies and tools used:
                </Text>
                <ul>
                  <li>React</li>
                  <li>JavaScript</li>
                  <li>Axios</li>
                  <li>CSS</li>
                  <li>React Router DOM</li>
                  <li>Visual Studio Code</li>
                </ul>
                <br /><br />
                If you're interested in the detail of the process and build (Documentation & Code) - <Link href='https://github.com/thisisgurpal/Pacman-Game' isExternal color='teal.500' fontWeight='bold'>Click here</Link>.
              </Text>
            </Box>
          </Flex>
        </>
      }
    </>
  );
};

export default PacmanJS;
