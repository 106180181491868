import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Text, Link as ChakraLink, Stack, Flex, Menu, MenuButton, MenuList, MenuItem, MenuDivider } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';


const Nav: React.FC = () => {
    return (
        <>
            <Flex paddingTop={10} paddingBottom={10} justifyContent='space-between' alignItems='center' w='100%'>
                <ChakraLink as={ReactRouterLink} to='/'>
                    <Text fontSize='4xl' as='mark'>GG</Text>
                </ChakraLink>
                <Stack direction='row' display={{ base: 'none', md: 'flex' }} spacing={6} align='center'>
                    <ChakraLink as={ReactRouterLink} to='/blogs'>
                        Blogs
                    </ChakraLink>
                    <ChakraLink as={ReactRouterLink} to='/projects'>
                        Projects
                    </ChakraLink>
                    <ChakraLink href='mailto:thisisgurpal@gmail.com' isExternal>
                        Contact
                    </ChakraLink>
                </Stack>
                <Menu>
                    <MenuButton
                        display={{ base: 'none', md: 'flex' }}
                        px={4}
                        py={2}
                        transition='all 0.2s'
                        borderRadius='md'
                        borderWidth='1px'
                        _hover={{ bg: 'gray.400' }}
                        _expanded={{ bg: 'blue.400' }}
                        _focus={{ boxShadow: 'outline' }}
                    >
                        Socials <ChevronDownIcon />
                    </MenuButton>
                    <MenuList>
                        <ChakraLink href='https://github.com/thisisgurpal' isExternal>
                            <MenuItem>GitHub</MenuItem>
                        </ChakraLink>
                        <ChakraLink href='https://www.linkedin.com/in/gurpal-gohler' isExternal>
                            <MenuItem>Linkedin</MenuItem>
                        </ChakraLink>
                        <ChakraLink href='https://x.com/gurpalgohler' isExternal>
                            <MenuItem>X</MenuItem>
                        </ChakraLink>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton
                        display={{ base: 'flex', md: 'none' }}
                        px={4}
                        py={2}
                        transition='all 0.2s'
                        borderRadius='md'
                        borderWidth='1px'
                        _hover={{ bg: 'gray.400' }}
                        _expanded={{ bg: 'blue.400' }}
                        _focus={{ boxShadow: 'outline' }}
                    >
                        Menu <ChevronDownIcon />
                    </MenuButton>
                    <MenuList>
                        <ChakraLink as={ReactRouterLink} to='/blogs'>
                            <MenuItem>Blogs</MenuItem>
                        </ChakraLink>
                        <ChakraLink as={ReactRouterLink} to='/projects'>
                            <MenuItem>Projects</MenuItem>
                        </ChakraLink>
                        <ChakraLink href='mailto:thisisgurpal@gmail.com' isExternal>
                            <MenuItem>Contact</MenuItem>
                        </ChakraLink>
                        <MenuDivider />
                        <ChakraLink href='https://github.com/thisisgurpal' isExternal>
                            <MenuItem>GitHub</MenuItem>
                        </ChakraLink>
                        <ChakraLink href='https://www.instagram.com/gurpalgohler/' isExternal>
                            <MenuItem>Instagram</MenuItem>
                        </ChakraLink>
                        <ChakraLink href='https://www.linkedin.com/in/gurpal-gohler' isExternal>
                            <MenuItem>Linkedin</MenuItem>
                        </ChakraLink>
                        <ChakraLink href='https://x.com/gurpalgohler' isExternal>
                            <MenuItem>X</MenuItem>
                        </ChakraLink>
                    </MenuList>
                </Menu>
            </Flex>

        </>

    );
};

export default Nav;