import { Link as ReactRouterLink } from 'react-router-dom'
import { Text, Box, Flex, Grid, GridItem, Link as ChakraLink, Badge } from '@chakra-ui/react';
import React from 'react';
import { blogs } from "./Data/BlogData";

const HomeSection1: React.FC = () => {
  const blogCount = blogs.length;
  return (
    <>
      {blogCount === 0 ?
        <>
        </>
        :
        <Box marginBottom={20}>
          <Flex mb={10} justifyContent='center' alignItems='center'>
            <Text fontSize='4xl' as='mark'>Blogs</Text>
          </Flex>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', xl: 'repeat(1, 1fr)' }}
            gap={2}
          >
            <Flex justifyContent='center' alignItems='center' w='100%'>
              {blogs.slice(0, 3).map((blog) => (
                <ChakraLink as={ReactRouterLink} to={blog.link}>
                  <GridItem borderRadius={10} colSpan={1}>
                    <Box maxW='100%' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                      <Box p='6'>
                        <Box display='flex' alignItems='baseline'>
                        </Box>
                        <Badge borderRadius='full' px='2' colorScheme='teal'>
                          New
                        </Badge>
                        <Box
                          mt='1'
                          fontWeight='semibold'
                          as='h4'
                          fontSize='30px'
                          lineHeight='tight'
                        >
                          {blog.title}
                        </Box>
                        <Box>
                          {blog.description}
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                </ChakraLink>
              ))}
            </Flex>

          </Grid>

        </Box>
      }
    </>


  );
};

export default HomeSection1;